<template>
  <div class="pb-2">
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <l-table-list-collector
      v-if="!loader"
      ref="lTableRef"
      :is-search-input-disabled="isEdit"
      :module-name="CATALOG_MODULE_NAME"
      :fetched-data="fetchedSubstitutes()"
      :table-columns="tableColumns"
      :item-id-for-check-itself="catalog.id"
      :table-suggestion-configs="autoSuggestTableColumns"
      @getCollectedList="getCollectedList"
    >
      <template #cell(action)="{ data: { item } }">
        <Can
          :i="ACCESS_ABILITY_SUBSTITUTES.action"
          :a="ACCESS_ABILITY_SUBSTITUTES.subject"
        >
          <div class="d-flex align-items-center">
            <button
              class="border-dashed btn p-0 mr-1"
              :disabled="isEdit"
              @click="remove(item)"
            >
              <feather-icon
                icon="LTrashIcon"
                size="30"
                style="padding: 4px"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
              />
            </button>

            <feather-icon
              icon="LEyeIcon"
              size="30"
              style="padding: 4px"
              class="cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor"
              @click="showProduct(item.id, $event)"
            />
          </div>
        </Can>
      </template>
      <template #cell(category)="{data: {item}}">
        {{ item.categories.map(item => item.name).join(', ') }}
      </template>

      <template
        #cell(pivot.is_direct_substitute)="{data}"
      >
        <b-form-checkbox
          :id="String(data.item.id)"
          v-model="data.item.is_direct_substitute"
          class="ml-4"
          :disabled="!$can(ACCESS_ABILITY_SUBSTITUTES.action, ACCESS_ABILITY_SUBSTITUTES.subject) || isEdit"
        />
      </template>
    </l-table-list-collector>
    <div
      v-if="loader"
    >
      <b-table-skeleton :fields="tableColumns" />
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            v-if="!isFormChanged"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="cancel"
          >
            {{ $t('Back to List') }}
          </b-button>
          <b-button
            v-else
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="loaderThis"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
            />
            {{ $t('Cancel') }}
          </b-button>
        </div>
        <div>
          <b-button
            v-if="$can(ACCESS_ABILITY_SUBSTITUTES.action, ACCESS_ABILITY_SUBSTITUTES.subject)"
            variant="success"
            class="saveBtn font-medium-1 font-weight-bolder"
            :disabled="!isFormChanged"
            @click="submit"
          >
            {{ $t('SAVE') }}
          </b-button>
        </div>
      </div>
    </portal>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
import {
  VBTooltip, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import config from '../substitutesConfig'

export default {
  name: 'SubstitutesList',
  components: {
    LTableListCollector,
    BFormCheckbox,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      collectedList: [],
      fetchedData: [],
      loader: false,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    substitutes() {
      return this.$store.state[this.MODULE_NAME].substitutesForm
    },
    catalog() {
      return this.$store.state[this.CATALOG_MODULE_NAME].catalog
    },
    substitutesFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].productSubstitutesClone
    },
    isFormChanged() {
      return JSON.stringify(this.substitutes) !== this.substitutesFormClone
    },
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
  },
  beforeRouteLeave(to, from, next) {
    this.showConfirmationModal(next)
  },

  created() {
    this.getSubstitutes()
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    fetchedSubstitutes() {
      return this.substitutes.substitutes
    },
    getSubstitutes() {
      this.loader = true
      this.$store.dispatch(`${this.MODULE_NAME}/getSubstitutes`, this.$route.params?.id)
        .then(res => {
          this.loader = false
          const { data } = res.data
          this.$store.commit(`${this.MODULE_NAME}/SET_SUBSTITUTES_FORM`, {
            substitutes: data.substitutes.map(substitute => ({
              ...substitute,
              is_direct_substitute: substitute.pivot.is_direct_substitute,
            })),
          })
          this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CATALOG_PRODUCT_SUBSTITUTES_CLONE`, {
            substitutes: data.substitutes.map(substitute => ({
              ...substitute,
              is_direct_substitute: substitute.pivot.is_direct_substitute,
            })),
          })
        })
        .catch(err => console.log(err))
    },
    getCollectedList(list) {
      this.$store.commit(`${this.MODULE_NAME}/SET_SUBSTITUTES_FORM`, {
        substitutes: list,
      })
    },
    submit() {
      this.onSubmit = true
      const data = this.substitutes
      const mapData = data.substitutes?.map(item => ({
        id: item.id,
        is_direct_substitute: item.is_direct_substitute ?? false,
      }))

      this.sendNotification(this, { substitutes: mapData }, `${this.MODULE_NAME}/updateSub`).then(() => {
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CATALOG_PRODUCT_SUBSTITUTES_CLONE`, { substitutes: mapData })
      }).catch(err => {
        this.error = err.response?.data
        this.errorNotification(this, err)
      }).finally(() => {
        this.getSubstitutes()
      })
    },
    clear() {
      this.$router.push({ name: 'home-catalog-substitutes' })
    },
    cancel() {
      this.clear()
      this.$router.push({ name: 'home-main-catalog-list' })
    },
    loaderThis() {
      const smth = JSON.parse(this.substitutesFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_SUBSTITUTES_FORM`, smth)
    },
    remove(data) {
      this.substitutes.substitutes = this.substitutes.substitutes.filter(substitute => substitute.id !== data.id)
      this.fetchedSubstitutes()
    },
    showProduct(data, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({ name: 'home-catalog-view', params: { id: data } })
        window.open(route.href)
      } else {
        this.$router.push({ name: 'home-catalog-view', params: { id: data } })
      }
    },
    isEditAble() {
      this.isEdit = !this.isEdit
    },
  },

  setup() {
    const MODULE_NAME = 'substitutes'
    const MODULE_NAME_CLONE = 'cloneData'
    const CATALOG_MODULE_NAME = 'catalogs'
    const { tableColumns, autoSuggestTableColumns, ACCESS_ABILITY_SUBSTITUTES } = config()

    return {
      tableColumns,
      MODULE_NAME,
      CATALOG_MODULE_NAME,
      MODULE_NAME_CLONE,
      autoSuggestTableColumns,
      ACCESS_ABILITY_SUBSTITUTES,
    }
  },
}
</script>
